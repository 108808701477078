export enum PublicStatus {
  Yes = "Y",
  No = "N",
}

export namespace PublicStatus {
  export function getKey(value: string) {
    return Object.keys(PublicStatus).find((key) => {
      return PublicStatus[key] == value;
    });
  }
}
