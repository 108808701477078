export class RegexUtils {
  // Simplified URL regex focusing on common patterns
  public static URL_REGEX = /(https?:\/\/[^\s]+|www\.[^\s]+)/;

  public static EMAIL_REGEX =
    /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  public static PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,10}$/;

  public static AT_LEAST_UPPER = /[A-Z]/;
  public static AT_LEAST_LOWER = /[a-z]/;
  public static AT_LEAST_DIGIT = /\d/;
  public static AT_LEAST_SPECIAL = /[@$!%*?&]/;

  public static isValid(regex: RegExp, value: string): boolean {
    return regex.test(value);
  }
}
