import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../new-login/service/login-service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent  {
  loading = false;
  formError = "";
  workEmail = "";
  successMessage = "";

  constructor(
    private router: Router,
    private loginService: LoginService,
  ) {}

  isEmailValid(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  onWorkEmailChange(newValue: string): void {
    this.formError = "";
    if (!this.isEmailValid(newValue)) {
      this.formError = "Please enter a valid email address";
      return;
    }
    this.workEmail = newValue;
  }
  navigateToLogin(): void {
    this.router.navigate(["login"]);
  }
  resetPassword(email: string): void {
    this.loginService.forgetPassword(email).subscribe(
      () => {
        this.loading = false;
        this.successMessage =
          "Link sent successfully, Please check your email to proceed !";
      },
      (error) => {
        this.loading = false;
        this.formError = error;
      },
    );
  }
  submitForm(): void {
    this.loading = true;
    if (this.formError !== "") {
      this.loading = false;
      return;
    }
    this.resetPassword(this.workEmail);
  }
}
