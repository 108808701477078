import { LoaderComponent } from "./loader/loader.component";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { AuthenticationGaurd } from "./guard/auth-guard";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonServiceService } from "./shared/services/common-service.service";
import { UserInvitationControllerService } from "./users/services/userInvitationController.service";
import { SystemPropertiesControllerService } from "./miscellaneous/services/systemPropertiesController.service";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { NewLoginComponent } from "./new-login/new-login.component";
import { CheckboxInputComponent } from "./checkbox-input/checkbox-input.component";
import { RegisterComponent } from "./register/register.component";
import { ToastrModule } from "ngx-toastr";
import { indicatorScurityIndexService } from "./indicator/services/indicatorScurityIndex.service";
import { AuthenticatedGuard } from "./guard/authenticated-guard";
import { JWtInterceptor } from "./interceptor/jwt-interceptor";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ComingsoonComponent } from "./comingsoon/comingsoon.component";

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NewLoginComponent,
    CheckboxInputComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ComingsoonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientXsrfModule.withOptions({ cookieName: "XSRF-TOKEN" }),
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthenticationGaurd,
    AuthenticatedGuard,
    CommonServiceService,
    UserInvitationControllerService,
    SystemPropertiesControllerService,
    indicatorScurityIndexService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: HTTP_INTERCEPTORS, useClass: JWtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
