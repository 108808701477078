import { AuthenticationGaurd } from "./guard/auth-guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NewLoginComponent } from "./new-login/new-login.component";
import { RegisterComponent } from "./register/register.component";
import { AuthenticatedGuard } from "./guard/authenticated-guard";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "",

    children: [
      {
        path: "",
        loadChildren: "./layout/layout.module#LayoutModule",
        canActivate: [AuthenticationGaurd],
      },
      {
        path: "*",
        loadChildren: "./layout/layout.module#LayoutModule",
        canActivate: [AuthenticationGaurd],
      },
    ],
  },
  {
    path: "login",
    component: NewLoginComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: "forget-password",
    component: ForgotPasswordComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: "comingsoon",
    component: ResetPasswordComponent,
    canActivate: [AuthenticatedGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
