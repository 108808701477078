import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[appNumbersOnly]",
})
export class NumbersOnlyDirective {
  @Input() allowDecimals = true;
  @Input() allowSign = false;
  @Input() decimalSeparator = ".";

  previousValue = "";

  // --------------------------------------
  //  Regular expressions
  integerUnsigned = "^[0-9]*$";
  integerSigned = "^-?[0-9]+$";
  decimalUnsigned = "^[0-9]+(.[0-9]+)?$";
  decimalSigned = "^-?[0-9]+(.[0-9]+)?$";

  /**
   * Class constructor
   * @param hostElement
   */
  constructor(private hostElement: ElementRef) {}

  /**
   * Event handler for host's change event
   * @param e
   */
  @HostListener("change", ["$event"]) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  /**
   * Event handler for host's paste event
   * @param e
   */
  @HostListener("paste", ["$event"]) onPaste(e) {
    // get and validate data from clipboard
    const value = e.clipboardData.getData("text/plain");
    this.validateValue(value);
    e.preventDefault();
  }

  /**
   * Event handler for host's keydown event
   * @param event
   */
  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    const cursorPosition: number = e.target["selectionStart"];
    const originalValue: string = e.target["value"];
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey || e.metaKey;

    if (
      this.isAllowedKey(key, controlOrCommand, cursorPosition, originalValue)
    ) {
      return;
    }

    this.previousValue = originalValue;

    if (!this.isNumericKey(key)) {
      e.preventDefault();
    }
  }

  private isAllowedKey(
    key: string,
    controlOrCommand: boolean,
    cursorPosition: number,
    originalValue: string,
  ): boolean {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Escape",
      "Tab",
    ];

    if (
      this.allowDecimals &&
      this.canAddDecimalSeparator(originalValue, cursorPosition)
    ) {
      allowedKeys.push(this.decimalSeparator === "." ? "." : ",");
    }

    if (this.allowSign && this.canAddMinusSign(originalValue, cursorPosition)) {
      allowedKeys.push("-");
    }

    return (
      allowedKeys.includes(key) ||
      (key === "a" && controlOrCommand) ||
      (key === "c" && controlOrCommand) ||
      (key === "v" && controlOrCommand) ||
      (key === "x" && controlOrCommand)
    );
  }

  private canAddDecimalSeparator(
    originalValue: string,
    cursorPosition: number,
  ): boolean {
    const signExists = originalValue.includes("-");
    const separatorExists = originalValue.includes(this.decimalSeparator);
    const separatorIsCloseToSign = signExists && cursorPosition <= 1;

    return !separatorIsCloseToSign && !separatorExists;
  }

  private canAddMinusSign(
    originalValue: string,
    cursorPosition: number,
  ): boolean {
    const signExists = originalValue.includes("-");
    const firstCharacterIsSeparator =
      originalValue.charAt(0) !== this.decimalSeparator;

    return !signExists && firstCharacterIsSeparator && cursorPosition === 0;
  }

  private isNumericKey(key: string): boolean {
    return new RegExp(this.integerUnsigned).test(key);
  }

  /**
   * Test whether value is a valid number or not
   * @param value
   */
  validateValue(value: string): void {
    // choose the appropiate regular expression
    let regex: string;
    if (!this.allowDecimals && !this.allowSign) regex = this.integerUnsigned;
    if (!this.allowDecimals && this.allowSign) regex = this.integerSigned;
    if (this.allowDecimals && !this.allowSign) regex = this.decimalUnsigned;
    if (this.allowDecimals && this.allowSign) regex = this.decimalSigned;

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value.charAt(0);
    if (firstCharacter == this.decimalSeparator) value = 0 + value;

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter == this.decimalSeparator) value = value + 0;

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const valid: boolean = new RegExp(regex).test(value);
    this.hostElement.nativeElement["value"] = valid ? value : 0;
  }

  /**
   *Get key's name
   *@param e
   */
  getName(e): string {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return "Backspace";
          case 9:
            return "Tab";
          case 27:
            return "Escape";
          case 37:
            return "ArrowLeft";
          case 39:
            return "ArrowRight";
          case 188:
            return ",";
          case 190:
            return ".";
          case 109:
            return "-"; // minus in numbpad
          case 173:
            return "-"; // minus in alphabet keyboard in firefox
          case 189:
            return "-"; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }
}
