import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageManager } from "../shared/utils/storage-manager";
import { CacheKeys } from "../shared/utils/cache-keys";
import { Utils } from "../shared/utils/utils";

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = StorageManager.getManager().getByType<any>(
      CacheKeys.JWT_TOKEN,
    );
    if (
      token &&
      !Utils.isEmpty(token) &&
      !Utils.isEmptyString(token["access_key"]) &&
      token["identity"] == "user"
    )
      this.router.navigate(["/users/registration"]);
    return true;
  }
}
