import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ServiceUtils } from "src/app/shared/utils/service-utils";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { IndicatorValueService } from "./interface/indicator-value.service";
import { IndicatorStatsResponseDTO } from "./interface/indicator-value-stats";
import { IndicatorDataValueDTOResponse } from "./interface/indicator-data-value-dto-response";
import { CountryDTO } from "./interface/country";
import { IndicatorTransactionDataResponseDTO } from "./interface/indicator-transaction-data-response-dto";
import { BaseResponse } from "./interface/base-response-dto";
import { Page } from "../../shared/interface/page";
import { IndicatorDataRequest } from "./interface/indicator-data-request";
import { IndicatorDataStatus } from "./interface/indicator-data-status";
import { Sort } from "../../shared/interface/sort";
import { PublicStatus } from "src/app/shared/interface/public-status";

@Injectable({
  providedIn: "root",
})
export class CountriesValueIndicatorService implements IndicatorValueService {
  constructor(private http: HttpClient) {}

  public getIndicators(
    categoryId?: number,
    year?: number,
    withData: boolean = false,
  ): Observable<any> {
    let url = `/qualityOfLifeIndicator/countries`;
    const paramsStr: string[] = [];

    paramsStr.push(`withData=${withData}`);

    if (categoryId) {
      paramsStr.push(`categoryId=${categoryId}`);
    }
    if (year) {
      paramsStr.push(`year=${year}`);
    }

    if (paramsStr.length) {
      url += `?${paramsStr.join("&")}`;
    }

    return this.http
      .get<IndicatorTransactionDataResponseDTO>(url)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getYearsWithData(): Observable<BaseResponse<number[]>> {
    return this.http
      .get<
        BaseResponse<number[]>
      >(ServiceUtils.INDICATOR_COUNTRIES_URL + "/years")
      .pipe(catchError(ServiceUtils.handleError));
  }

  // public getIndicatorValues(countryId?:number, indicatorId?:number, year?:number): Observable < IndicatorTransactionDataResponseDTO> {
  //   let url = `${ServiceUtils.INDICATOR_COUNTRIES_URL}`;

  //   let paramsStr: string[] =[];

  //   if (countryId) {
  //     paramsStr.push(`countryId=${countryId}`);
  //   }
  //   if (indicatorId) {
  //     paramsStr.push(`indicatorId=${indicatorId}`);
  //   }
  //   if (year) {
  //     paramsStr.push(`year=${year}`);
  //   }

  //   if(paramsStr.length){
  //     url+=`?${paramsStr.join('&')}`;
  //   }

  //   return this.http.get < IndicatorTransactionDataResponseDTO > (url).pipe(catchError(ServiceUtils.handleError));
  // }

  public changeStatus(selectedUserId, status: string, note?): Observable<any> {
    const url = ServiceUtils.INDICATOR_COUNTRIES_CHANGE_STATUS_URL;
    return this.http
      .post<any>(url, {
        id: selectedUserId,
        status: status.toUpperCase(),
        note: note,
      })
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getCountries(
    indicatorId?: number,
    year?: number,
  ): Observable<CountryDTO[]> {
    let url = `${ServiceUtils.COUNTRIES_URL}`;

    const paramsStr: string[] = [];

    if (indicatorId) {
      paramsStr.push(`indicatorId=${indicatorId}`);
    }
    if (year) {
      paramsStr.push(`year=${year}`);
    }

    if (paramsStr.length) {
      url += `?${paramsStr.join("&")}`;
    }

    return this.http
      .get<CountryDTO[]>(url)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getAllCountries(): Observable<CountryDTO[]> {
    const url = `${ServiceUtils.ALL_COUNTRIES_URL}`;

    return this.http
      .get<CountryDTO[]>(url)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public viewAttachment(id): Observable<any> {
    const url = ServiceUtils.INDICATOR_COUNTRIES_VIEW_ATTACH_URL + "/" + id;
    return this.http.get<any>(url).pipe(catchError(ServiceUtils.handleError));
  }

  public deleteIndicatorValue(id): Observable<any> {
    const url = ServiceUtils.INDICATOR_COUNTRIES_DELETE_URL;
    return this.http
      .post<any>(url, {
        id: id,
      })
      .pipe(catchError(ServiceUtils.handleError));
  }

  public createIndicatorValue(indicatorValueModel): Observable<any> {
    const url = ServiceUtils.INDICATOR_COUNTRIES_URL;
    return this.http
      .post<any>(url, {
        countryId: indicatorValueModel.countryId,
        indicatorId: indicatorValueModel.indicatorId,
        year: indicatorValueModel.year,
        value: indicatorValueModel.value,
        referenceUrl: indicatorValueModel.referenceUrl,
        authorityLogo: indicatorValueModel.authorityLogoBase64,
        authorityLogoMimeType: indicatorValueModel.authorityLogoMimeType,
        attachment: indicatorValueModel.attachmentBase64,
        attachmentMimeType: indicatorValueModel.attachmentMimeType,
        isPublic: indicatorValueModel.isPublic,
      })
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getPendingIndicatorValues(
    page: Page,
    sort: Sort,
    search?: string,
  ): Observable<BaseResponse<IndicatorDataValueDTOResponse>> {
    const url = ServiceUtils.DASHBOARD_COUNTRIES_DATA_URL;
    const request: IndicatorDataRequest = {
      data: {
        isPublic: PublicStatus.No,
        status: IndicatorDataStatus.Pending,
        search: search,
      },
      page: page,
      sort: sort,
    };
    return this.http
      .post<BaseResponse<IndicatorDataValueDTOResponse>>(url, request)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getIndicatorStatistics(): Observable<IndicatorStatsResponseDTO> {
    const url = ServiceUtils.DASHBOARD_COUNTRIES_STATISTICS_URL;
    return this.http
      .get<IndicatorStatsResponseDTO>(url)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public getIndicatorValues(
    request?: IndicatorDataRequest,
  ): Observable<BaseResponse<IndicatorDataValueDTOResponse>> {
    const url = ServiceUtils.DASHBOARD_COUNTRIES_DATA_URL;

    return this.http
      .post<BaseResponse<IndicatorDataValueDTOResponse>>(url, request)
      .pipe(catchError(ServiceUtils.handleError));
  }
}
