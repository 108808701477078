import { Component, OnInit, isDevMode } from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import { LoginService } from "./new-login/service/login-service";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { logWarnings } from "protractor/built/driverProviders"; //NOSONAR

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "web";

  constructor(private loginService: LoginService) {
    console.log(isDevMode());
  }

  ngOnInit(): void {
    am4core.options.queue = true;
    am4core.options.onlyShowOnViewport = true;
  }
}
