import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterService } from "./service/register.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  fullname = "";
  username = "";
  email = "";
  password = "";
  confirmPassword = "";

  emailerr = "Please Provide a valid email format !!";
  emailError = "";

  pwsErr =
    "Password must be 8-30 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.";
  passwordError = "";
  confirmPasswordError = "";
  emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,30}$/;
  loading = false;
  token = "";
  nameErro = "Fullname must be between 3 to 20 characters";
  fullnameError = "";
  usernameErr = "Fullname must be between 3 to 20 characters";
  usernameError = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get("token") || "";
  }
  onFullnameChange(value: string) {
    this.fullnameError = "";
    if (value.length < 3 || value.length > 20) {
      this.fullnameError = this.nameErro;
    }
    this.fullname = value;
  }
  onUsernameChange(value: string) {
    this.usernameError = "";
    if (value.length < 3 || value.length > 20) {
      this.usernameError = this.usernameErr;
    }
    this.username = value;
  }
  onEmailChange(value: string) {
    this.emailError = "";
    if (!this.emailRegex.test(value)) {
      this.emailError = this.emailerr;
    }
    this.email = value;
  }
  onPasswordChange(value: string) {
    this.passwordError = "";
    if (!this.passwordRegex.test(value)) {
      this.passwordError = this.pwsErr;
    }
    this.password = value;
  }
  onConfirmPasswordChange(value: string) {
    this.confirmPasswordError = "";
    if (value !== this.password) {
      this.confirmPasswordError = "Passwords do not match !!";
    }
    this.confirmPassword = value;
  }
  navigateToRegister() {
    this.router.navigate(["/login"]);
  }
  onSubmit() {
    this.loading = true;
    if (
      this.fullnameError ||
      this.usernameError ||
      this.emailError ||
      this.passwordError ||
      (this.confirmPasswordError && this.username.length === 0) ||
      this.fullname.length === 0 ||
      this.email.length === 0 ||
      this.password.length === 0 ||
      this.confirmPassword.length === 0
    ) {
      this.toastr.error(
        "Please ensure the validity of the form fields and try again !",
      );
      this.loading = false;
      return;
    }
    this.loading = true;
    console.log(
      this.fullname,
      this.username,
      this.email,
      this.password,
      this.confirmPassword,
    );
    this.registerService
      .register(
        this.fullname,
        this.username,
        this.email,
        this.password,
        this.token,
      )
      .subscribe(
        () => {
          this.toastr.success("Successfully registered !");
          setTimeout(() => {
            this.loading = false;
            this.router.navigate(["/login"]);
          }, 2000);
        },
        (error: string) => {
          this.loading = false;
          this.toastr.error(error);
        },
      );
  }
}
