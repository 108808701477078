import { throwError } from "rxjs";

export class ServiceUtils {
  public static APPCONFIG_URL = "/app/config";
  public static AUTH_URL = "/admin/auth/login"; // "/auth";
  public static REG_URL = "/admin/auth/signup";
  public static RESET_PASSWORD_URL = "/resetPassword";
  public static CHANGE_PASSWORD_URL = "/changePassword";

  public static REGISTERED_USERS_URL = "/users/registration";
  public static REGISTERED_USERS_CHANGE_STATUS_URL =
    "/users/registration/changeStatus";
  public static ALL_EMPLOYEE_USERS_URL = "/users";
  public static ADD_EMPLOYEE_USERS_URL = "/users/add";
  public static EDIT_EMPLOYEE_USERS_URL = "/users/edit/";
  public static DELETE_WEBPORTAL_USERS_URL = "/users/delete";
  public static DELETE_EMPLOYEE_USERS_URL = "/employees/delete";
  public static REGISTERED_USERS_ENABLE_LOGIN_URL = "/users/enableLogin";

  public static REGISTERED_USERS_VIEW_ATTACH_URL =
    "/users/registration/viewAttachment";
  public static DASHBOARD_COUNTRIES_STATISTICS_URL =
    "/indicator/countries/stat";
  public static DASHBOARD_PENDING_COUNTRIES_URL =
    "/indicator/countries/pending";
  public static DASHBOARD_COUNTRIES_DATA_URL = "/indicator/countries/data";
  public static DASHBOARD_USERS_STATUS_STATISTICS_URL = "/user/stat";

  public static COUNTRIES_URL = "/countries";
  public static ALL_COUNTRIES_URL = "/all-countries";
  public static INDICATOR_COUNTRIES_URL = "/indicator/countries";
  public static INDICATOR_COUNTRIES_VIEW_ATTACH_URL =
    "/indicator/countries/viewAttachment";
  public static INDICATOR_COUNTRIES_CHANGE_STATUS_URL =
    "/indicator/countries/changeStatus";
  public static INDICATOR_COUNTRIES_DELETE_URL = "/indicator/countries/delete";

  public static DASHBOARD_PENDING_CITIES_URL = "/indicator/cities/pending";
  public static DASHBOARD_CITIES_STATISTICS_URL = "/indicator/cities/stat";
  public static CITIES_URL = "/cities";
  public static ALL_CITIES_URL = "/all-cities";
  public static INDICATOR_CITIES_URL = "/indicator/cities";
  public static INDICATOR_CITIES_VIEW_ATTACH_URL =
    "/indicator/cities/viewAttachment";
  public static INDICATOR_CITIES_CHANGE_STATUS_URL =
    "/indicator/cities/changeStatus";
  public static INDICATOR_CITIES_DELETE_URL = "/indicator/cities/delete";
  public static DASHBOARD_CITIES_DATA_URL = "/indicator/cities/data";

  /** handle http calls exception*/
  public static handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      if (typeof error.error == "string") errorMessage = `${error.error}`;
      else if (error.error && error.error.message)
        errorMessage = `${error.error.message}`;
      // else
      // errorMessage = 'General Error ! Please Contact Support Team';
    }
    // AlertUtils.error('Error', errorMessage);
    // console.log(errorMessage)
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  public static toFormData(requestObject: Request): FormData {
    const formData = new FormData();

    Object.keys(requestObject).forEach((key) => {
      if (requestObject[key]) formData.append(key, requestObject[key]);
    });
    return formData;
  }
}
