import { LoadingService } from "./service/loading-service";
import { Component } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.css"],
})
export class LoaderComponent {
  constructor(public loaderService: LoadingService) {}
}
