import { Component } from "@angular/core";

@Component({
  selector: "app-comingsoon",
  templateUrl: "./comingsoon.component.html",
  styleUrls: ["./comingsoon.component.scss"],
})
export class ComingsoonComponent {

}
