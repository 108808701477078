import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-error",
  templateUrl: "./page-error.component.html",
  styleUrls: ["./page-error.component.scss"],
})
export class PageErrorComponent {
  @Input() show: boolean;

  refresh() {
    window.location.reload();
  }
}
