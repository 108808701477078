export class CacheKeys {
  public static REMEBER_ME = "cach.key.remeber.me";
  public static JWT_TOKEN = "cach.key.jwt.token";
  public static JWT_REFRESH_TOKEN = "cach.key.jwt.refresh.token";
  public static USER_ID = "cach.key.user.id";
  public static USER_NAME = "cach.key.user.name";
  public static USER_ENGLISH_NAME = "cach.key.user.english.name";
  public static USER_EMAIL = "cach.key.user.email";
  public static USER_LAST_LOGIN_DATE = "cach.key.user.last.login";
  public static USER_ROLE = "cach.key.user.role";
}
