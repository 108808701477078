import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlatMapComponent } from "./flat-map/flat-map.component";
import { CountryMapComponent } from "./country-map/country-map.component";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [FlatMapComponent, CountryMapComponent],
  imports: [
    CommonModule,
    FlexLayoutModule.withConfig({
      addFlexToParent: false,
    }),
  ],
  exports: [FlatMapComponent, CountryMapComponent],
})
export class MapsModule {}
