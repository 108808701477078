import {
  Component,
  OnInit,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldUltra from "@amcharts/amcharts4-geodata/worldHigh";
import { AbstractMapComponent } from "../abstract-map-component";
import { Utilities } from "../utilities";

import { IGeoPoint } from "@amcharts/amcharts4/core";
import { City } from "src/app/indicator/services/interface/city";

@Component({
  selector: "app-country-map",
  templateUrl: "./country-map.component.html",
  styleUrls: ["./country-map.component.scss"],
})
export class CountryMapComponent
  extends AbstractMapComponent
  implements OnInit, OnChanges
{
  private countryName: string;
  @Input() color: string;
  private city: City;

  private citySeries: am4maps.MapImageSeries;
  private cityCoordinates: IGeoPoint;
  private readonly zoomlevel = 10;

  constructor(private zone: NgZone) {
    super();
  }

  render = async () => {
    if (this._map) {
      this.dispose();
    }
    const map = (this._map = am4core.create(this.id, am4maps.MapChart));

    try {
      map.geodata = (
        (await Utilities.getGeoData(this.countryName)) as any
      ).default;
    } catch (err) {
      map.geodata = am4geodata_worldUltra;
    }

    map.projection = new am4maps.projections.Miller();
    map.fill = am4core.color("#fff");
    map.backgroundSeries.fillOpacity = 1;
    map.seriesContainer.draggable = true;
    map.seriesContainer.resizable = true;
    map.maxZoomLevel = this.zoomlevel;

    const countrySeries = map.series.push(new am4maps.MapPolygonSeries());

    countrySeries.useGeodata = true;
    countrySeries.calculateVisualCenter = true;

    const countryTemplate = countrySeries.mapPolygons.template;

    countryTemplate.fill = am4core.color(this.color);
    countryTemplate.fillOpacity = 1;
    countryTemplate.stroke = am4core.color(this.color);
    countryTemplate.strokeOpacity = 1;
    countryTemplate.nonScalingStroke = true;
    countryTemplate.propertyFields.id = "id";

    if (this.indicatorData && this.indicatorData.length) {
      const indicatorData = this.indicatorData[0];
      if (!indicatorData.city) {
        return;
      }


      const mapData = {
        id: indicatorData.id,
        name: indicatorData.name,
        value: indicatorData.value,
        longitude: new Number(indicatorData.city.longitude).valueOf(),
        latitude: new Number(indicatorData.city.latitude).valueOf(),
      };

      map.seriesContainer.draggable = true;
      map.seriesContainer.resizable = true;
      map.maxZoomLevel = this.zoomlevel;
      map.minZoomLevel = 1;
      map.homeGeoPoint = mapData;

      countryTemplate.stroke = am4core.color("#fff");

      this.citySeries = map.series.push(new am4maps.MapImageSeries());

      this.citySeries.data = [mapData];

      const cityTemplate = this.citySeries.mapImages.template;

      cityTemplate.nonScaling = true;
      cityTemplate.horizontalCenter = "middle";
      cityTemplate.verticalCenter = "middle";
      cityTemplate.align = "center";
      cityTemplate.valign = "middle";
      cityTemplate.propertyFields.latitude = "latitude";
      cityTemplate.propertyFields.longitude = "longitude";

      const circle = cityTemplate.createChild(am4core.Image);
      circle.href = "/global_assets/images/target.svg";
      circle.width = 20;
      circle.height = 20;

      circle.scale = 0.75;
      circle.fill = am4core.color("#008755");
      circle.stroke = am4core.color("#008755");
      circle.opacity = 1;

      if (this.tooltip) {
        cityTemplate.tooltipHTML = `<div class="flatmap-tooltip" style="display:flex;flex-direction:column; justify-content: center;">
                      <div class="text" style="text-align: center;">{name} &nbsp;{value}</div>
                  <div>`;

        this.citySeries.tooltip.label.interactionsEnabled = true;
        this.citySeries.tooltip.keepTargetHover = true;
        this.citySeries.tooltip.getFillFromObject = false;
        this.citySeries.tooltip.background.fill = am4core.color("#FFFFFF");
        this.citySeries.tooltip.autoTextColor = false;
        this.citySeries.tooltip.label.fill = am4core.color("#000000");
        this.citySeries.tooltip.label.fontSize = "1.5rem";
        cityTemplate.tooltipPosition = "fixed";
      }

      this.citySeries.events.on("ready", () => {
        const center = true;
        this._map.zoomToGeoPoint(this.cityCoordinates, this.zoomlevel, center);
      });
    }
  };

  ngOnInit() {
    if (!this.id) {
      this.id = "countryMapDiv_" + new Date().getMilliseconds();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.indicatorData) {
      if (this._map) {
        this.zone.runOutsideAngular(() => {
          this.render();
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.render();
    });
  }

  viewHome() {
    if (this._map) {
      if (this.city) {
        this._map.zoomToGeoPoint(this.cityCoordinates, this.zoomlevel, true);
        return;
      }
      this._map.goHome();
    }
  }

  private dispose() {
    this._map.clearCache();
    this._map.dispose();
    this._map = null;
  }
}
