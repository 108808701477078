import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GlobalIndicatorType } from "src/app/globalIndicator/interfaces/global-indicator";

@Injectable({
  providedIn: "root",
})
export class CommonServiceService {
  inviteButtonClick: EventEmitter<any> = new EventEmitter();

  popupButtonClick: EventEmitter<any> = new EventEmitter();

  createButtonClick: EventEmitter<any> = new EventEmitter();

  inviteModalOpen = true;

  successUserInvitation: EventEmitter<any> = new EventEmitter();

  private indicatorSource = new BehaviorSubject<GlobalIndicatorType | null>(
    null,
  );
  indicator$: Observable<GlobalIndicatorType | null> =
    this.indicatorSource.asObservable();

  private refreshSource = new Subject<void>();
  refresh$ = this.refreshSource.asObservable();
  triggerRefresh(): void {
    this.refreshSource.next(); // Emit a refresh signal
  }

  setIndicator(indicator: GlobalIndicatorType): void {
    this.indicatorSource.next(indicator);
  }

  getIndicator(): GlobalIndicatorType | null {
    return this.indicatorSource.value;
  }

  clearIndicator(): void {
    this.indicatorSource.next(null);
  }


}
