import { Component } from "@angular/core";
import { LoginService } from "./service/login-service";
import { Router } from "@angular/router";
import { StorageManager } from "../shared/utils/storage-manager";
import { CacheKeys } from "../shared/utils/cache-keys";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-new-login",
  templateUrl: "./new-login.component.html",
  styleUrls: ["./new-login.component.scss"],
})
export class NewLoginComponent {
  isChecked = false;
  username = "";
  usernameError = "";
  password = "";
  passwordError = "";
  loading = false;

  handleCheckboxClick(): void {
    this.isChecked = !this.isChecked; // Toggle checkbox state
  }
  onUsernameChange(newValue: string) {
    this.usernameError = "";

    this.username = newValue;
  }
  onPasswordChange(newValue: string) {
    this.passwordError = "";

    this.password = newValue;
  }
  isEmailValid(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  isValidPassword(password: string): boolean {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,30}$/;
    return passwordRegex.test(password);
  }
  submitForm() {
    this.loading = true;
    if (this.usernameError || this.passwordError) {
      this.loading = false;
      return;
    }
    this.Login(this.username, this.password, this.isChecked);
  }

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
  ) {}


  private Login(userName: string, password: string, rememberMe: boolean): void {
    this.loading = true;
    this.loginService.login(userName, password, rememberMe).subscribe(
      (resp: any) => {
        this.loading = false;
        const bearerToken = resp.accessToken;
        StorageManager.getManager().set(CacheKeys.JWT_TOKEN, {
          access_key: bearerToken.trim(),
          identity: "user",
        });
        StorageManager.getManager().set(CacheKeys.JWT_REFRESH_TOKEN, {
          refresh_key: resp.refreshToken.trim(),
          identity: "refresh",
        });

        StorageManager.getManager().set(CacheKeys.USER_ID, resp.user.id);
        StorageManager.getManager().set(
          CacheKeys.USER_NAME,
          resp.user.fullName,
        );
        StorageManager.getManager().set(CacheKeys.USER_EMAIL, resp.user.email);
        StorageManager.getManager().set(
          CacheKeys.USER_ENGLISH_NAME,
          resp.user.fullName,
        );
        StorageManager.getManager().set(CacheKeys.USER_ROLE, resp.user.role);

        if (resp.user.forceChangePassword) {
          this.router.navigate(["/change-password"]);
        } else {
          this.router.navigate(["/users/registration"]);
        }
      },
      (error: any) => {
        this.loading = false;
        this.toastr.error("Somthing went wrong ");
      },
    );
  }
}
