import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServiceUtils } from "src/app/shared/utils/service-utils";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { EmployeeModel } from "../models/employee.model";
import { BaseResponse } from "src/app/indicator/services/interface/base-response-dto";
import { EmployeesUsers } from "src/app/shared/objects/EmployeesUsers";

@Injectable()
export class EmployeesService {
  constructor(private http: HttpClient) {}

  public getAllEmplyeeUsers(): Observable<any> {
    const url = ServiceUtils.ALL_EMPLOYEE_USERS_URL;
    return this.http.get<any>(url).pipe(catchError(ServiceUtils.handleError));
  }

  public saveNewEmployee(model: EmployeeModel): Observable<any> {
    const url = ServiceUtils.ADD_EMPLOYEE_USERS_URL;
    return this.http
      .post<any>(url, model)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public updateEmployee(model: EmployeeModel): Observable<any> {
    const url = ServiceUtils.EDIT_EMPLOYEE_USERS_URL;
    return this.http
      .post<any>(url + model.id, model)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public deleteEmployee(id: number): Observable<any> {
    const url = ServiceUtils.DELETE_EMPLOYEE_USERS_URL;
    return this.http
      .get<BaseResponse<EmployeesUsers>>(url + `/${id}`)
      .pipe(catchError(ServiceUtils.handleError));
  }
}
