import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AppConstants } from "src/app/common-scripts/AppConstants";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { StorageManager } from "src/app/shared/utils/storage-manager";
import { CacheKeys } from "src/app/shared/utils/cache-keys";
import { catchError } from "rxjs/operators";
import {
  ApproveUsersResponse,
  PendingUsersResponse,
  RejectUsersResponse,
  User,
} from "src/app/shared/interface/user";
import {
  Admin,
  AdminList,
} from "src/app/globalIndicator/interfaces/global-indicator";

@Injectable()
export class UserManagmentService {
  private apiUrl = environment.apiUrl;
  protected basePath;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
    this.basePath = AppConstants.baseURL;
  }
  private userSource = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSource.asObservable();

  private adminSource = new BehaviorSubject<Admin | null>(null);
  admin$: Observable<Admin | null> = this.adminSource.asObservable();

  setUser(user: User): void {
    this.userSource.next(user);
  }

  clearUser(): void {
    this.userSource.next(null);
  }
  clearAsmin(): void {
    this.adminSource.next(null);
  }
  getAdmin(): Admin | null {
    return this.adminSource.value;
  }
  setAdmin(admin: Admin): void {
    this.adminSource.next(admin);
  }

  getUser(): User | null {
    return this.userSource.value;
  }

  reserPasswrdUser(email: FormData): Observable<any> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken);
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );
    const url = `${this.apiUrl}/user/reset/reset-password`;
    return this.httpClient.post<any>(url, email).pipe(
      catchError((error: any) => {
        console.error("Error fetching senind reset password:", error);
        return throwError(error);
      }),
    );
  }
  reserPasswrdAdmin(email: FormData): Observable<any> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken);
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );
    const url = `${this.apiUrl}/admin/reset/reset-password`;
    return this.httpClient.post<any>(url, email).pipe(
      catchError((error: any) => {
        console.error("Error fetching senind reset password:", error);
        return throwError(error);
      }),
    );
  }
  getPendingUsers(
    page: number,
    size: number,
    sortCriteria: string,
    sortOrder: string,
  ): Observable<PendingUsersResponse> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken);
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    // Only send one sortCriteria and sortOrder to the API
    const url = `${this.apiUrl}/admin/users/pending?page=${page}&size=${size}&${sortCriteria}SortCriteria=${sortOrder}`;

    return this.httpClient.get<PendingUsersResponse>(url, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching pending users:", error);
        return throwError(error);
      }),
    );
  }

  acceptPendingUsers(id: number): Observable<any> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);
    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/users/${id}/accept`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.post<any>(url, {}, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching pending users:", error);
        return throwError(error);
      }),
    );
  }
  rejectPendingUsers(id: number): Observable<any> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);
    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/users/${id}/refuse`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.post<any>(url, {}, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error rejecting pending user:", error);
        return throwError(error);
      }),
    );
  }

  getRejectedUsers(
    page: number,
    size: number,
    sortCriteria: string,
    sortOrder: string,
  ): Observable<PendingUsersResponse> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/users/rejected?page=${page}&size=${size}&${sortCriteria}SortCriteria=${sortOrder}`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.get<RejectUsersResponse>(url, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching pending users:", error);
        return throwError(error);
      }),
    );
  }
  getApprovedUsers(
    page?: number,
    size?: number,
    sortCriteria?: string,
    sortOrder?: string,
  ): Observable<ApproveUsersResponse> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/users/approved?page=${page}&size=${size}&${sortCriteria}SortCriteria=${sortOrder}`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.get<PendingUsersResponse>(url, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching pending users:", error);
        return throwError(error);
      }),
    );
  }

  getAllpendingUsers(): Observable<ApproveUsersResponse> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/users/pending`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.get<PendingUsersResponse>(url, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching pending users:", error);
        return throwError(error);
      }),
    );
  }

  getAllAdmins(): Observable<AdminList> {
    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );

    const url = `${this.apiUrl}/admin/`;
    console.log(`Fetching users from: ${url}`);
    console.log("Authorization Header:", headers);

    return this.httpClient.get<AdminList>(url, { headers }).pipe(
      catchError((error: any) => {
        console.error("Error fetching admins:", error);
        return throwError(error);
      }),
    );
  }

  inviteAdmin(data: FormData): Observable<any> {
    console.log("formdata", data);

    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );
    const url = `${this.apiUrl}/admin/invite-admin`;
    return this.httpClient.post<any>(url, data, { headers });
  }
  inviteUsers(data: FormData): Observable<any> {
    console.log("formdata", data);

    const yourToken = StorageManager.getManager().get(CacheKeys.JWT_TOKEN);

    if (!yourToken) {
      console.error("No token found!");
      return throwError("No token found");
    }

    // Parse the token string to get the access_key
    let accessKey: string;
    try {
      const parsedToken = JSON.parse(yourToken); // Assuming yourToken is a JSON string
      accessKey = parsedToken.access_key;
    } catch (error) {
      console.error("Error parsing token:", error);
      return throwError("Invalid token format");
    }

    if (!accessKey) {
      console.error("No access_key found in token!");
      return throwError("No access_key found");
    }

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${accessKey}`,
    );
    const url = `${this.apiUrl}/admin/invite-user`;
    return this.httpClient.post<any>(url, data, { headers });
  }
}
