export interface Sort {
  sortBy: string;
  sortOrder: SortOrder;
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export namespace SortOrder {
  export function valueOf(stringInput: string): SortOrder {
    const stringOrder = stringInput.toUpperCase();
    return SortOrder[stringOrder];
  }

  export function keys(): string[] {
    return Object.keys(SortOrder).filter((key) => key != "keys");
  }
}
