
import * as am4maps from "@amcharts/amcharts4/maps";
import { Input } from "@angular/core";

export abstract class AbstractMapComponent {
  @Input() id: string;
  @Input() type: string;
  @Input() height: string;
  @Input() width: number;
  @Input() indicatorData: any[];
  @Input() tooltip: boolean;
  protected _map: am4maps.MapChart;

  abstract render(indicatorData?: any[], tooltip?: boolean);


}
