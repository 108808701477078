import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { AppConstants } from "src/app/common-scripts/AppConstants";
import { SystemPropertiesDTO } from "src/app/shared/objects/systemPropertiesDTO";
import { Observable } from "rxjs";

@Injectable()
export class SystemPropertiesControllerService {
  protected basePath;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {
    this.basePath = AppConstants.baseURL;
  }

  public getAllSystemPropertiesUsingGET(): Observable<
    Array<SystemPropertiesDTO>
  > {
    return this.httpClient.get<Array<SystemPropertiesDTO>>(
      `${this.basePath}systemProperties`,
      {},
    );
  }

  public updateSystemPropertyUsingPOST(
    systemPropertiesDTO: SystemPropertiesDTO,
  ): Observable<any> {
    if (systemPropertiesDTO === null || systemPropertiesDTO === undefined) {
      throw new Error(
        "Required parameter systemPropertiesDTO was null or undefined when calling updateSystemPropertyUsingPOST.",
      );
    }

    return this.httpClient.post<any>(
      `${this.basePath}updateSystemProperty`,
      systemPropertiesDTO,
      {},
    );
  }

  public getValueByKeyUsingGET(
    propertyKey: string,
  ): Observable<SystemPropertiesDTO> {
    if (propertyKey === null || propertyKey === undefined) {
      throw new Error(
        "Required parameter propertyKey was null or undefined when calling getValueByKeyUsingGET.",
      );
    }

    let queryParameters = new HttpParams();
    if (propertyKey !== undefined && propertyKey !== null) {
      queryParameters = queryParameters.set("propertyKey", <any>propertyKey);
    }

    return this.httpClient.get<SystemPropertiesDTO>(
      `${this.basePath}systemPropertyValue`,
      {
        params: queryParameters,
      },
    );
  }
}
