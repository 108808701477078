import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { StorageManager } from "src/app/shared/utils/storage-manager";
import { ServiceUtils } from "src/app/shared/utils/service-utils";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private apiUrl = environment.apiUrl;

  public constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public login(
    username: string,
    password: string,
    rememberMe,
    requireChnagePassword?,
  ): any {
    const url = this.apiUrl + ServiceUtils.AUTH_URL;
    StorageManager.getManager().clearBoth();
    StorageManager.getManager().initSessionMode(rememberMe);
    return this.http.post(url, { username, password }).pipe(
      catchError((error) => {
        return ServiceUtils.handleError(error);
      }),
    );
  }
  public refreshToken(refreshToken: string): Observable<any> {
    const url = this.apiUrl + "/admin/auth/refresh";
    return this.http
      .post(url, { refreshToken })
      .pipe(catchError(ServiceUtils.handleError));
  }

  /* public acquireAnonymousToken(overwrite:boolean = false) {
        let url = ServiceUtils.AUTH_URL;

        //while acquiring token if there is already one, skip the call, but if overwrite param is sent another token should acquired and replace the current one.
        if (!overwrite && StorageManager.getManager().get(CacheKeys.JWT_TOKEN))
            return;

        this.http.post(url, { userName: AppConstants.ANONYUN, password: AppConstants.ANONYPS }).subscribe( (resp:any) => {

            if (resp.success) {
                let bearerToken = resp['accessToken'];
                StorageManager.getManager().set(CacheKeys.JWT_TOKEN, { access_key: bearerToken.trim(), identity: 'anonymous' });
                StorageManager.getManager().set(CacheKeys.USER_ROLE, resp['role']);
            }
        }, error => {
            ServiceUtils.handleError(error);
        });

    } */

  public forgetPassword(email: string): Observable<any> {
    const url = this.apiUrl + "/admin/reset/reset-password";
    const formdata = new FormData();
    formdata.append("email", email);
    return this.http
      .post(url, formdata)
      .pipe(catchError(ServiceUtils.handleError));
  }
  public resetPassword(token: string, password: string): Observable<any> {
    const url = this.apiUrl + "/admin/reset/change-password";
    const formdata = new FormData();
    formdata.append("token", token);
    formdata.append("password", password);
    return this.http
      .post(url, formdata)
      .pipe(catchError(ServiceUtils.handleError));
  }

  public changePassword(
    userName,
    oldPassword,
    newPassword,
    confrmPassword,
  ): Observable<any> {
    const url = ServiceUtils.CHANGE_PASSWORD_URL;
    return this.http
      .post(url, {
        userName: userName,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confrmPassword,
      })
      .pipe(catchError(ServiceUtils.handleError));
  }

  public logOut(): void {
    StorageManager.getManager().clear();
    this.router.navigate(["/login"]);
  }
}
