import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
})
export class FormInputComponent {
  @Input() name!: string;
  @Input() inputType = "text";
  @Input() placeholder!: string;
  @Input() value!: string;
  @Input() error?: string;
  @Input() isRequired = false;
  @Input() style?: { [key: string]: string };
  @Output() valueChange = new EventEmitter<string>();
  isFocused = false;
  isPasswordVisible = false;
  handleFocus(): void {
    this.isFocused = true;
  }

  handleBlur(): void {
    this.isFocused = false;
  }

  handleInputChange(event: any): void {
    this.valueChange.emit(event.target.value);
  }

  togglePasswordVisibility(event: MouseEvent): void {
    event.preventDefault();
    this.isPasswordVisible = !this.isPasswordVisible;
  }


}
