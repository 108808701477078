import { string } from '@amcharts/amcharts4/core';

export enum IndicatorDataStatus{
    New = 'new',
    Pending = 'new',
    Approved = 'approved',
    Rejected = 'rejected'
}


export namespace IndicatorDataStatus{
    export function valueOf(stringInput:string):IndicatorDataStatus{
        if(!stringInput){
            return null;
        }
        const key = IndicatorDataStatus.keys().find(key => stringInput.toLowerCase() == key.toLowerCase());
        return IndicatorDataStatus[key];
    }

    export function keys():string[] {
        return Object.keys(IndicatorDataStatus).filter(key => key != 'keys');
    }

}